export const Color = {
  LIGHT: '#dde3e6',
  LIGHT_GRAY: '#8f8f8f',
  GRAY: '#383838',
  YELLOW: '#daa42d',
  BLUE: '#5991f7',
  GREEN: '#309070',
  RED: '#f75959',
};

export const HideOverflow = `overflow:hidden;
text-overflow:ellipsis;
white-space:nowrap;`;
