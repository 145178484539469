import { EventEmitter } from 'events';
import { io, Socket } from 'socket.io-client';

export default class Pingpong extends EventEmitter {
  private readonly socket: Socket;

  constructor(code: string) {
    super();
    this.socket = io(`https://taskboard.delta-kor.repl.co/?code=${code}`);
    this.loadListeners();
  }

  public connect(): void {
    this.socket.connect();
  }

  private loadListeners(): void {
    this.socket.on('disconnect', () => this.emit('disconnect'));
    this.socket.on('user', (user: string) => this.emit('user', user));
    this.socket.on('data', (data: ITask[]) => this.emit('data', data));
    this.socket.on('error', (message: string) => this.emit('error', message));
  }

  public assign(hash: string): void {
    this.socket.emit('assign_task', hash);
  }

  public unassign(hash: string): void {
    this.socket.emit('unassign_task', hash);
  }

  public updateState(id: string, state: TaskState): void {
    this.socket.emit('update_state', id, state);
  }

  public setCdnId(id: string, cdnId?: string): void {
    this.socket.emit('set_cdnid', id, cdnId);
  }
}
