import { useEffect, useState } from 'react';
import styled from 'styled-components';
import Header from './components/Header';
import Task from './components/Task';
import Pingpong from './pingpong';
import { Color } from './styles';

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 8px;
  gap: 8px;
`;

const Tasks = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin: 164px 0 0 0;
`;

const Filter = styled.div`
  display: flex;
  padding: 8px 0;
  border-top: 3px solid ${Color.LIGHT};
  border-bottom: 3px solid ${Color.LIGHT};
  gap: 8px;
`;

const Chip = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const Label = styled.label`
  font-size: 20px;
  cursor: pointer;
  user-select: none;
`;

const Checkbox = styled.input`
  width: 16px;
  height: 16px;
  cursor: pointer;
`;

const Sticky = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  padding: 0 8px;
  gap: 8px;

  background: white;
  z-index: 1;
`;

const App: React.FC = () => {
  const [onlyMe, setOnlyMe] = useState<boolean>(false);
  const [stateFilter, setStateFilter] = useState<TaskState | null>(null);
  const [user, setUser] = useState<string>('Unknown');
  const [tasks, setTasks] = useState<ITask[]>([]);
  const [pingpong, setPingpong] = useState<Pingpong | null>(null);

  useEffect(() => {
    const code = prompt('아이디를 입력하세요');
    if (!code) return alert('취소되었습니다');

    const pingpong = new Pingpong(code);
    setPingpong(pingpong);

    pingpong.on('disconnect', () => alert('연결이 끊어졌습니다'));
    pingpong.on('user', (user: string) => {
      alert(`${user} 로 로그인했습니다`);
      setUser(user);
    });
    pingpong.on('data', (tasks: ITask[]) =>
      setTasks(tasks.sort((a, b) => a.category[0].localeCompare(b.category[0])))
    );
    pingpong.on('error', (message: string) => alert(message));

    pingpong.connect();
  }, []);

  const filteredTask = tasks
    .filter((task) => (onlyMe ? task.assigned === user : true))
    .filter((task) => {
      if (!stateFilter) return true;

      const videos = task.videos;

      let state: TaskState = 'waiting';
      if (!videos.some((video) => video.state === 'waiting'))
        state = 'uploaded';
      if (videos.every((video) => video.state === 'uploaded'))
        state = 'uploaded';
      if (videos.every((video) => video.state === 'deployed'))
        state = 'deployed';
      if (videos.some((video) => video.state === 'error')) state = 'error';

      return state === stateFilter;
    });

  return (
    <Layout>
      <Sticky>
        <Header tasks={tasks} />
        <Filter>
          <Chip>
            <Checkbox
              id={'me'}
              type={'checkbox'}
              checked={onlyMe}
              onChange={() => setOnlyMe(!onlyMe)}
            />
            <Label htmlFor={'me'}>내 할당량만 보기</Label>
          </Chip>
          <div />
          <div />
          <div />
          <Chip>
            <Checkbox
              id={'all'}
              type={'radio'}
              checked={stateFilter === null}
              onChange={() => setStateFilter(null)}
            />
            <Label htmlFor={'all'}>전체</Label>
          </Chip>
          <Chip>
            <Checkbox
              id={'waiting'}
              type={'radio'}
              checked={stateFilter === 'waiting'}
              onChange={() => setStateFilter('waiting')}
            />
            <Label htmlFor={'waiting'}>대기</Label>
          </Chip>
          <Chip>
            <Checkbox
              id={'uploaded'}
              type={'radio'}
              checked={stateFilter === 'uploaded'}
              onChange={() => setStateFilter('uploaded')}
            />
            <Label htmlFor={'uploaded'}>업로드완료</Label>
          </Chip>
          <Chip>
            <Checkbox
              id={'deployed'}
              type={'radio'}
              checked={stateFilter === 'deployed'}
              onChange={() => setStateFilter('deployed')}
            />
            <Label htmlFor={'deployed'}>배포중</Label>
          </Chip>
          <Chip>
            <Checkbox
              id={'error'}
              type={'radio'}
              checked={stateFilter === 'error'}
              onChange={() => setStateFilter('error')}
            />
            <Label htmlFor={'error'}>오류</Label>
          </Chip>
        </Filter>
      </Sticky>
      <Tasks>
        {filteredTask.map((task) => (
          <Task task={task} pingpong={pingpong} me={user} key={task.hash} />
        ))}
        {filteredTask.length === 0 && <div>데이터가 없습니다</div>}
      </Tasks>
    </Layout>
  );
};

export default App;
