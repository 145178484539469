import styled from 'styled-components';
import Pingpong from '../pingpong';
import { Color, HideOverflow } from '../styles';

function getDate(number: number): string {
  const date = new Date(number);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const dayOfMonth = date.getDate();

  return `${year.toString().slice(-2)}${padNumber(month)}${padNumber(
    dayOfMonth
  )}`;
}

function padNumber(number: number): string {
  return number.toString().padStart(2, '0');
}

function getDuration(number: number): string {
  number = Math.round(number);
  const minutes = ((number / 60) | 0) % 60;
  const seconds = number - ((number / 60) | 0) * 60;
  const hours = (number / 60 / 60) | 0;
  return hours
    ? `${hours}:${padNumber(minutes)}:${padNumber(seconds)}`
    : `${minutes}:${padNumber(seconds)}`;
}

const Layout = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const Thumbnail = styled.img`
  height: 36px;
  aspect-ratio: 16 / 9;
`;

const Title = styled.a`
  width: 200px;
  font-size: 18px;
  font-weight: bold;
  color: ${Color.GRAY};
  text-decoration: none;
  ${HideOverflow};
`;

const Duration = styled.div`
  width: 100px;
  font-size: 18px;
  ${HideOverflow};
`;

const Button = styled.div<{ $color: string; $active: boolean }>`
  display: inline-block;
  background: ${({ $active, $color }) => ($active ? $color : Color.LIGHT)};
  color: ${({ $active }) => ($active ? 'white' : Color.GRAY)};
  padding: 8px 12px;
  max-width: 148px;

  font-size: 12px;
  font-weight: bold;
  border-radius: 4px;

  cursor: pointer;
  user-select: none;
  transition: background 0.2s, color 0.2s;
  ${HideOverflow};
`;

const Cluster = styled.div`
  display: flex;
  gap: 4px;
`;

interface Props {
  video: IVideo;
  assigned: string | null;
  me: string;
  pingpong: Pingpong | null;
}

const Video: React.FC<Props> = ({ video, assigned, me, pingpong }) => {
  const handleStateClick = (state: TaskState) => {
    if (state === video.state) return false;

    if (me !== assigned)
      if (!window.confirm('내 할당량이 아닙니다.\n상태를 변경하겠습니까?'))
        return false;

    pingpong?.updateState(video.id, state);
  };

  const handleCdnId = () => {
    const cdnId = prompt('CDN ID', video.cdnId || '');
    if (cdnId === null) return alert('취소되었습니다');

    pingpong?.setCdnId(video.id, cdnId || undefined);
  };

  return (
    <Layout>
      <Thumbnail
        src={`https://api2.izflix.net/thumbnail/${video.id}`}
        loading="lazy"
      />
      <Title href={`https://izflix.vercel.app/${video.id}`} target={'_blank'}>
        {video.title}
      </Title>
      <Duration>{getDate(new Date(video.date).getTime())}</Duration>
      <Duration>{getDuration(video.duration)}</Duration>
      <Duration>{video.is4K && '4K'}</Duration>
      <Cluster>
        <Button
          $color={assigned ? Color.GRAY : Color.LIGHT_GRAY}
          $active={video.state === 'waiting'}
          onClick={() => handleStateClick('waiting')}
        >
          대기
        </Button>
        <Button
          $color={Color.GREEN}
          $active={video.state === 'uploaded'}
          onClick={() => handleStateClick('uploaded')}
        >
          업로드완료
        </Button>
        <Button
          $color={Color.BLUE}
          $active={video.state === 'deployed'}
          onClick={() => handleStateClick('deployed')}
        >
          배포중
        </Button>
        <Button
          $color={Color.RED}
          $active={video.state === 'error'}
          onClick={() => handleStateClick('error')}
        >
          오류
        </Button>
        <div />
        <div />
        <div />
        <Button
          $color={Color.RED}
          $active={false}
          onClick={() => handleCdnId()}
        >
          {video.cdnId || 'CDN 설정'}
        </Button>
      </Cluster>
    </Layout>
  );
};

export default Video;
