import styled from 'styled-components';
import { Color } from '../styles';
import { tasksToVideos } from '../utils';

const Layout = styled.div`
  display: flex;
  flex-direction: column;
`;

const Percentage = styled.div`
  font-size: 48px;
  font-weight: bold;
`;

const Items = styled.div`
  display: flex;
  gap: 16px;
`;

const Item = styled.div<{ $color: string }>`
  font-size: 20px;
  font-weight: bold;
  color: ${({ $color }) => $color};
`;

interface Props {
  tasks: ITask[];
}

const Header: React.FC<Props> = ({ tasks }) => {
  const videos = tasksToVideos(tasks);
  const waitingVideos = videos.filter((item) => item.state === 'waiting');
  const uploadedVideos = videos.filter((item) => item.state === 'uploaded');
  const deployedVideos = videos.filter((item) => item.state === 'deployed');
  const errorVideos = videos.filter((item) => item.state === 'error');

  return (
    <Layout>
      <Percentage>
        {Math.round(
          ((deployedVideos.length / videos.length) * 100 + Number.EPSILON) * 100
        ) / 100}
        %
      </Percentage>
      <Items>
        <Item $color={Color.GRAY}>전체 : {videos.length}</Item>
        <Item $color={Color.YELLOW}>대기 : {waitingVideos.length}</Item>
        <Item $color={Color.GREEN}>업로드완료 : {uploadedVideos.length}</Item>
        <Item $color={Color.BLUE}>배포중 : {deployedVideos.length}</Item>
        <Item $color={Color.RED}>오류 : {errorVideos.length}</Item>
      </Items>
    </Layout>
  );
};

export default Header;
