import styled from 'styled-components';
import Pingpong from '../pingpong';
import { Color } from '../styles';
import Video from './Video';

const Layout = styled.div`
  display: flex;
  flex-direction: column;
`;

const Content = styled.div<{ $color: string }>`
  position: relative;
  display: flex;
  justify-content: space-between;
  gap: 8px;
  padding: 12px 24px;

  cursor: default;
  border: 3px solid transparent;
  transition: border 0.2s;

  &:hover {
    border: 3px solid ${({ $color }) => $color};
  }
`;

const Status = styled.div<{ $color: string }>`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 8px;
  background: ${({ $color }) => $color};
  transition: background 0.2s;
`;

const Category = styled.a<{ $color: string }>`
  font-weight: bold;
  font-size: 20px;
  color: ${({ $color }) => $color};
  text-decoration: none;
  cursor: pointer;
  transition: color 0.2s;
`;

const Action = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`;

const Button = styled.div`
  display: inline-block;
  background: ${Color.LIGHT};
  padding: 8px 12px;
  font-size: 12px;
  font-weight: bold;
  border-radius: 4px;
  cursor: pointer;
  user-select: none;
`;

const Assigned = styled.div<{ $color: string }>`
  font-size: 12px;
  font-weight: bold;
  color: ${({ $color }) => $color};
`;

const Videos = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 0 0 24px;
  gap: 4px;
`;

interface Props {
  task: ITask;
  pingpong: Pingpong | null;
  me: string;
}

const Task: React.FC<Props> = ({ task, pingpong, me }) => {
  const videos = task.videos;

  let color: string = Color.LIGHT_GRAY;
  if (task.assigned) color = Color.YELLOW;
  if (!videos.some((video) => video.state === 'waiting')) color = Color.GREEN;
  if (videos.every((video) => video.state === 'uploaded')) color = Color.GREEN;
  if (videos.every((video) => video.state === 'deployed')) color = Color.BLUE;
  if (videos.some((video) => video.state === 'error')) color = Color.RED;

  return (
    <Layout>
      <Content $color={color}>
        <Status $color={color} />
        <Category
          $color={color}
          href={`https://izflix.vercel.app/category/${task.hash}`}
          target="_blank"
        >
          {task.category.join(' / ')}
        </Category>
        <Action>
          <Assigned
            $color={
              task.assigned === me
                ? Color.BLUE
                : task.assigned
                ? Color.GRAY
                : Color.LIGHT_GRAY
            }
          >
            {task.assigned || '미할당'}
          </Assigned>
          <Button
            onClick={() => {
              if (task.assigned && task.assigned !== me)
                if (!window.confirm('이미 할당 되었습니다\n덮어쓰겠습니까?'))
                  return false;
              pingpong?.assign(task.hash);
            }}
          >
            나에게 할당
          </Button>
          <Button
            onClick={() => {
              if (task.assigned && task.assigned !== me)
                if (
                  !window.confirm(
                    '다른 사용자에게 할당 되었습니다\n할당을 취소하겠습니까?'
                  )
                )
                  return false;
              pingpong?.unassign(task.hash);
            }}
          >
            할당 취소
          </Button>
        </Action>
      </Content>
      <Videos>
        {videos.map((video) => (
          <Video
            video={video}
            assigned={task.assigned}
            me={me}
            pingpong={pingpong}
            key={video.id}
          />
        ))}
      </Videos>
    </Layout>
  );
};

export default Task;
